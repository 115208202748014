/**
 * Проверяет коллизию двух объектов
 */
export default function checkCollision(
  unit1: { width: number; height: number; x: number; y: number },
  unit2: { width: number; height: number; x: number; y: number },
  {
    rangeYTop = 0,
    rangeYBottom = 0,
    offset = {},
  }: {
    rangeYTop?: number;
    rangeYBottom?: number;
    rangeX?: number;
    offset?: { yTop?: number; yBottom?: number };
  }
) {
  const { x: x1, y: y1, width: width1, height: height1 } = unit1;
  const { x: x2, y: y2, width: width2, height: height2 } = unit2;

  let XColl = false;
  let YColl = false;

  if (x1 + width1 / 2 >= x2 - width2 / 2 && x1 - width1 / 2 <= x2 + width2 / 2)
    XColl = true;
  if (
    y1 + height1 / 2 + rangeYBottom >= y2 - height2 / 2 &&
    y1 - height1 / 2 - rangeYTop <= y2 + height2 / 2
  )
    YColl = true;

  if (XColl && YColl) {
    return true;
  }
  return false;
}
