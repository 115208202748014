export enum LOCALES {
  play = 'play',
  profile = 'profile',
  shop = 'shop',
  rating = 'rating',
  mascot_name = 'mascot_name',
  skip_button = 'skip_button',
  first_tutorial_window = 'first_tutorial_window',
  second_tutorial_window = 'second_tutorial_window',
  third_tutorial_window = 'third_tutorial_window',
  fourth_tutorial_window = 'fourth_tutorial_window',
  fifth_tutorial_window = 'fifth_tutorial_window',
  sixth_tutorial_window = 'sixth_tutorial_window',
  seventh_tutorial_window = 'seventh_tutorial_window',
  eigth_tutorial_window = 'eigth_tutorial_window',
  nineth_tutorial_window = 'nineth_tutorial_window',
  tenth_tutorial_window = 'tenth_tutorial_window',
  eleventh_tutorial_window = 'eleventh_tutorial_window',
  twelfth_tutorial_window = 'twelfth_tutorial_window',
  thirteenth_tutorial_window = 'thirteenth_tutorial_window',
  game_result_victory = 'game_result_victory',
  game_result_defeat = 'game_result_defeat',
  to_menu_button = 'to_menu_button',
  shard = 'shard',
  bonuses_button = 'bonuses_button',
  create_button = 'create_button',
  create_button_text = 'create_button_text',
  chest_placeholder = 'chest_placeholder',
  Tutorial = 'Tutorial',
  Sound_on = 'Sound_on',
  Sound_off = 'Sound_off',
  coins_32 = 'coins_32',
  card_1 = 'card_1',
  Shards = 'Shards',
  Open = 'Open',
  event_end_text_title = 'event_end_text_title',
  event_end_text = 'event_end_text',
  General = 'General',
  Friends = 'Friends',
  shop_title = 'shop_title',
  deal_title = 'deal_title',
  chests_title = 'chests_title',
  chest_rarity_regular = 'chest_rarity_regular',
  chest_rarity_rare = 'chest_rarity_rare',
  chest_rarity_special = 'chest_rarity_special',
  coins_title = 'coins_title',
  coins_amount_little = 'coins_amount_little',
  coins_amount_regular = 'coins_amount_regular',
  coins_amount_large = 'coins_amount_large',
  Emotions = 'Emotions',
  Emotion = 'Emotion',
  tabbar_deck = 'tabbar_deck',
  tabbar_stats = 'tabbar_stats',
  Cards_collection = 'Cards_collection',
  cards_collection_progress = 'cards_collection_progress',
  cards_locked = 'cards_locked',
  cards_button_info = 'cards_button_info',
  cards_button_choose = 'cards_button_choose',
  card_specs_title = 'card_specs_title',
  card_specs_rarity = 'card_specs_rarity',
  card_specs_speed = 'card_specs_speed',
  card_specs_power = 'card_specs_power',
  card_specs_cost = 'card_specs_cost',
  card_specs_recharge = 'card_specs_recharge',
  card_specs_Yrange = 'card_specs_Yrange',
  card_specs_Xrange = 'card_specs_Xrange',
  card_specs_wind = 'card_specs_wind',
  card_specs_corn = 'card_specs_corn',
  card_specs_spawn = 'card_specs_spawn',
  card_specs_weight = 'card_specs_weight',
  card_rarity_regular = 'card_rarity_regular',
  card_rarity_rare = 'card_rarity_rare',
  card_rarity_epic = 'card_rarity_epic',
  card_sheep = 'card_sheep',
  card_sheep_desc = 'card_sheep_desc',
  card_farm = 'card_farm',
  card_farm_desc = 'card_farm_desc',
  card_pasture = 'card_pasture',
  card_pasture_desc = 'card_pasture_desc',
  card_pasture_specs_time = 'card_pasture_specs_time',
  card_bigSheep = 'card_bigSheep',
  card_bigSheep_desc = 'card_bigSheep_desc',
  card_bee = 'card_bee',
  card_bee_desc = 'card_bee_desc',
  card_teslaTower = 'card_teslaTower',
  card_teslaTower_desc = 'card_teslaTower_desc',
  card_doubleFarm = 'card_doubleFarm',
  card_doubleFarm_desc = 'card_doubleFarm_desc',
  card_crashedPlane = 'card_crashedPlane',
  card_crashedPlane_desc = 'card_crashedPlane_desc',
  card_deadFarm = 'card_deadFarm',
  card_deadFarm_desc = 'card_deadFarm_desc',
  card_fastSheep = 'card_fastSheep',
  card_fastSheep_desc = 'card_fastSheep_desc',
  card_beehiveFarm = 'card_beehiveFarm',
  card_beehiveFarm_desc = 'card_beehiveFarm_desc',
  card_necromancerSheep = 'card_necromancerSheep',
  card_necromancerSheep_desc = 'card_necromancerSheep_desc',
  card_mortar = 'card_mortar',
  card_mortar_desc = 'card_mortar_desc',
  card_icePeak = 'card_icePeak',
  card_icePeak_desc = 'card_icePeak_desc',
  card_potion = 'card_potion',
  card_potion_desc = 'card_potion_desc',
  card_royalSheep = 'card_royalSheep',
  card_royalSheep_desc = 'card_royalSheep_desc',
  card_mole = 'card_mole',
  card_mole_desc = 'card_mole_desc',
  card_cycloneSheep = 'card_cycloneSheep',
  card_cycloneSheep_desc = 'card_cycloneSheep_desc',
  card_balloonBase = 'card_balloonBase',
  card_balloonBase_desc = 'card_balloonBase_desc',
  card_balloon = 'card_balloon',
  card_invisibleSheep = 'card_invisibleSheep',
  card_invisibleSheep_desc = 'card_invisibleSheep_desc',
  waiting_title_screen = 'waiting_title_screen',
  waiting_text = 'waiting_text',
  any_user = 'any_user',
  invite_opponent = 'invite_opponent',
  battle_1_vs_1 = 'battle_1_vs_1',
  invite = 'invite',
  bot_chest_unlocked = 'bot_chest_unlocked',
  bot_hi_im_sheeppy = 'bot_hi_im_sheeppy',
  bot_day_tasks = 'bot_day_tasks',
  bot_tasks_will_update_in = 'bot_tasks_will_update_in',
  bot_tasks_completed_and_new = 'bot_tasks_completed_and_new',
  bot_task_completed = 'bot_task_completed',
  bot_tasks_completed_and_new_2 = 'bot_tasks_completed_and_new_2',
  bot_day_tasks_2 = 'bot_day_tasks_2',
  bot_day_tasks_3 = 'bot_day_tasks_3',
  bot_day_tasks_4 = 'bot_day_tasks_4',
  support = 'support',
  bot_daily_tasks = 'bot_daily_tasks',
  tasks_list = 'tasks_list',
  bot_refresh = 'bot_refresh',
  Play_3_battle = 'Play_3_battle',
  Win_1_battle = 'Win_1_battle',
  Win_2_battles_in_a_row = 'Win_2_battles_in_a_row',
  Win_3_battles_without_using_mortar = 'Win_3_battles_without_using_mortar',
  Win_3_battles_without_using_flask_of_portion = 'Win_3_battles_without_using_flask_of_portion',
  Win_3_battles_without_using_regular_farm = 'Win_3_battles_without_using_regular_farm',
  Win_3_battles_without_using_buildings_in_a_deck = 'Win_3_battles_without_using_buildings_in_a_deck',
  Win_3_battles_without_using_ice_peak_and_tesla_tower_in_a_deck = 'Win_3_battles_without_using_ice_peak_and_tesla_tower_in_a_deck',
  Win_1_battle_using_royal_sheep_in___deck = 'Win_1_battle_using_royal_sheep_in___deck',
  Win_3_battles_using_beehive_and_flask_in_a_deck = 'Win_3_battles_using_beehive_and_flask_in_a_deck',
  Win_1_battle_using_necromancer_sheep_in_a_deck = 'Win_1_battle_using_necromancer_sheep_in_a_deck',
  Win_3_battles_without_using_sheep_in_a_deck = 'Win_3_battles_without_using_sheep_in_a_deck',
  Win_3_battles_using_plane_in_a_deck = 'Win_3_battles_using_plane_in_a_deck',
  Win_3_battles_without_using_pasture_and_beehive_in_a_deck = 'Win_3_battles_without_using_pasture_and_beehive_in_a_deck',
  Win_3_battles_using_beehive_and_pasture_in_a_deck = 'Win_3_battles_using_beehive_and_pasture_in_a_deck',
  Win_3_battles_using_tesla_tower_in_a_deck = 'Win_3_battles_using_tesla_tower_in_a_deck',
  Open_2_chest = 'Open_2_chest',
  Buy_any_items_for_voices_in_the_shop = 'Buy_any_items_for_voices_in_the_shop',
  Buy_coins_for_voices_in_the_shop = 'Buy_coins_for_voices_in_the_shop',
  Buy_any_chets_for_coins_in_the_shop = 'Buy_any_chets_for_coins_in_the_shop',
  Buy_special_chets_for_coins_in_the_shop = 'Buy_special_chets_for_coins_in_the_shop',
  Complete_all_tasks_for_today = 'Complete_all_tasks_for_today',
  game_mode = 'game_mode',
  game_result_draw = 'game_result_draw',
  in_the_search = 'in_the_search',
  to_shop_button = 'to_shop_button',
  to_close_button = 'to_close_button',
  players_online = 'players_online',
  sound_off = 'sound_off',
  cancel_the_serach = 'cancel_the_serach',
  what_inside = 'what_inside',
  shards = 'shards',
  will_open = 'will_open',
  it_will_open_instantly = 'it_will_open_instantly',
  express_emotions = 'express_emotions',
  another_chest_opening = 'another_chest_opening',
  deal_text = 'deal_text',
  chest_title = 'chest_title',
  coint_title = 'coint_title',
  player = 'player',
  player_s = 'player_s',
  players = 'players',
  stats_victories = 'stats_victories',
  stats_defeates = 'stats_defeates',
  stats_drawss = 'stats_drawss',
  cards = 'cards',
  card = 'card',
  collected = 'collected',
  from = 'from',
  blocked_emotions = 'blocked_emotions',
  emotion_collection = 'emotion_collection',
  cells = 'cells',
  power = 'power',
  brings = 'brings',
  behind = 'behind',
  sec = 'sec',
  before = 'before',
  card_rarity_epics = 'card_rarity_epics',
  card_rarity_lega = 'card_rarity_lega',
  card_rarity_legendary = 'card_rarity_legendary',
  card_rarity_legend = 'card_rarity_legend',
  time_hour = 'time_hour',
  time_hour_s = 'time_hour_s',
  time_hours = 'time_hours',
  time_h = 'time_h',
  time_m = 'time_m',
  time_mines = 'time_mines',
  time_minute = 'time_minute',
  corn = 'corn',
  not_enough_coins = 'not_enough_coins',
  the_advertisement_is_over = 'the_advertisement_is_over',
  emo_battle = 'emo_battle',
  will_end_in = 'will_end_in',
  time_s = 'time_s',
  time_d = 'time_d',
  choose_card_for_replacement = 'choose_card_for_replacement',
  cancle = 'cancle',
  copy_link = 'copy_link',
  invited_text = 'invited_text',
  time = 'time',
  skip_text = 'skip_text',
  surrender = 'surrender',
  connecting_to_the_game_text = 'connecting_to_the_game_text',
  shard_added_to_inventory_text = 'shard_added_to_inventory_text',
  invite_friends = 'invite_friends',
  receive = 'receive',
  reward_friends_text = 'reward_friends_text',
  for_each = 'for_each',
  missions = 'missions',
  bonus_available = 'bonus_available',
  daily_bonus = 'daily_bonus',
  next_bonus_text = 'next_bonus_text',
  every_day_reward_text = 'every_day_reward_text',
  received = 'received',
  link_copied = 'link_copied',
  chose_for_emotions = 'chose_for_emotions',
  Loading = 'Loading',
  star = 'star',
  stars_1 = 'stars_1',
  stars_2 = 'stars_2',
  prizes = 'prizes',
  cost = 'cost',
  energy = 'energy',
  out_power = 'out_power',
  fight = 'fight',
  please_send_wallet_address = 'please_send_wallet_address',
  invalid_wallet_address = 'invalid_wallet_address',
  successfully_set_wallet_address = 'successfully_set_wallet_address',
  privacy_police = 'privacy_police',
  ordinary = 'ordinary',
  coins = 'coins',
  time_minutes = 'time_minutes',
  exceptCards = 'exceptCards',
  onlyCards = 'onlyCards',
  for_passing = 'for_passing',
  award = 'award',
  limitation = 'limitation',
  adventures = 'adventures',
  pass_all_the_levels = 'pass_all_the_levels',
  blocked = 'blocked',
  unlocked = 'unlocked',
  seconds = 'seconds',
  stars = 'stars',
}
