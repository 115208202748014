import { PlayerType } from "@/panels/Lobby/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type LobbyObject = { _id: string; url: string };

type LobbyState = {
  chosenPlayer: PlayerType | null;
  initialLobbyState: LobbyObject | null;
};

const initialState: LobbyState = {
  chosenPlayer: null,
  initialLobbyState: null,
};

const LobbySlice = createSlice({
  name: "Lobby",
  initialState,
  reducers: {
    setPlayer(state: LobbyState, action: PayloadAction<PlayerType | null>) {
      state.chosenPlayer = action.payload;
    },
    setInitialLobbyState(
      state: LobbyState,
      action: PayloadAction<LobbyObject | null>
    ) {
      state.initialLobbyState = action.payload;
      console.log(state.initialLobbyState)
    },
  },
});

export default LobbySlice.reducer;
export const { setPlayer, setInitialLobbyState } = LobbySlice.actions;
