import { RandomHowl } from "@/utils/randomHowl";
import { GAME_SOUND_VOLUME } from "./game";
import { sounds } from "../url";
import { EmotionType } from "@/types/emotionCard";

export const EMOTIONS_SOUND_VOLUME = 1;
export const beeGiveUpSound = new RandomHowl([
  {
    src: [sounds.emotions.beeGiveUp[0]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.beeGiveUp[1]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.beeGiveUp[2]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
]);

export const beeGoodLuckSound = new RandomHowl([
  {
    src: [sounds.emotions.beeGoodLuck[0]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.beeGoodLuck[1]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.beeGoodLuck[2]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
]);

export const beeWaitSound = new RandomHowl([
  {
    src: [sounds.emotions.beeWait[0]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.beeWait[1]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.beeWait[2]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
]);

export const moleSneerSound = new RandomHowl([
  {
    src: [sounds.emotions.moleSneer[0]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.moleSneer[1]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.moleSneer[2]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
]);

export const sheepAngrySound = new RandomHowl([
  {
    src: [sounds.emotions.sheepAngry[0]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.sheepAngry[1]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.sheepAngry[2]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
]);

export const sheepSadSound = new RandomHowl([
  {
    src: [sounds.emotions.sheepSad[0]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.sheepSad[1]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.sheepSad[2]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
]);

export const sheepSorrySound = new RandomHowl([
  {
    src: [sounds.emotions.sheepSorry[0]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.sheepSorry[1]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.sheepSorry[2]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
]);

export const sheepyHelloSound = new RandomHowl([
  {
    src: [sounds.emotions.sheepyHello[0]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.sheepyHello[1]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
  {
    src: [sounds.emotions.sheepyHello[2]],
    volume: EMOTIONS_SOUND_VOLUME,
  },
]);

export const emotionSounds: { [key: string]: RandomHowl } = {
  sheepAngry: sheepAngrySound,
  sheepyHello: sheepyHelloSound,
  sheepSad: sheepSadSound,
  sheepSorry: sheepSorrySound,
  moleSneer: moleSneerSound,
  beeGiveUp: beeGiveUpSound,
  beeGoodLuck: beeGoodLuckSound,
  beeWait: beeWaitSound,
};
