import Sprite, { SpriteProps } from "../../utils/Sprite";
import * as PIXI from "pixi.js";
import { CardType } from "./CardsController";
import { Socket } from "socket.io-client";

import { OutlineFilter, DropShadowFilter } from "pixi-filters";
import { Engine } from "@/../../engine";
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  GAME_CANVAS_HEIGHT,
  GAME_CANVAS_WIDTH,
} from "../../config";
import SpineSprite from "@/pixi/utils/SpineSprite";
import { SPRITES_SETTINGS } from "@/pixi/units/config";
import {
  BUILDINGS_CONFIG,
  ENTITIES_CONFIG,
} from "../../../../../engine/units/config";
import checkCollision from "../../../../../engine/utils/CheckCollision";

/**
 * Класс управляющий карточками и отправляющий запросы на сервер
 */

function isEven(num: number) {
  return num % 2 === 0;
}

const ErrorOutlineFilter = new OutlineFilter(1, 0xffffff, 2);
export class Card extends PIXI.Container {
  selected = false;
  socket: Socket;
  preventPosition: { x: number; y: number } = { x: 0, y: 0 };
  unitClass: "entity" | "building";
  unitType: string;
  spriteProps: SpriteProps;
  sprites: { [key: string]: PIXI.Texture };
  gameObjectsContainer: PIXI.Container;
  container: PIXI.Container;
  sprite: PIXI.Container;
  isBuildMode = false;
  engine: Engine;
  myId: number;
  canSpawn = false;
  spawnCoords: { x: number; y: number } = { x: 0, y: 0 };
  unitProps: { width: number; height: number };
  buildingContainer: PIXI.Container;
  cardContainer: PIXI.Container;

  constructor({
    spriteProps,
    card,
    sprites,
    socket,
    gameObjectsContainer,
    container,
    engine,
    myId,
  }: {
    sprites: { [key: string]: PIXI.Texture };
    spriteProps: SpriteProps;
    card: CardType;
    socket: Socket;
    gameObjectsContainer: PIXI.Container;
    container: PIXI.Container;
    engine: Engine;
    myId: number;
  }) {
    super();

    const cardCallbacks = [
      ...(spriteProps?.callbacks || []),
      { action: "pointertap", fn: () => this.onPointerTap() },
      { action: "pointerdown", fn: () => this.onPointerDown() },
      { action: "pointerup", fn: () => this.onPointerUp() },
      // {
      //   action: "pointermove",
      //   fn: (event: PIXI.FederatedPointerEvent) => this.onPointerMove(event),
      // },
      { action: "pointerupoutside", fn: () => this.onPointerUpOutSide() },
    ];
    // Предсоздаем все спрайты, чтобы менять их при необходимости
    const cardSprite = new Sprite(sprites[card.unitType + "Card"], {
      scale: spriteProps.scale,
      isButton: true,
      x: 3,
      y: 3,
    });

    const CardFormMask = new PIXI.Graphics();
    CardFormMask.beginFill(0x650a5a, 1);
    CardFormMask.drawRoundedRect(3, 3, cardSprite.width, cardSprite.height, 18);
    CardFormMask.endFill();
    cardSprite.mask = CardFormMask;

    const borderSprite = new Sprite(sprites["cardBorder"]);
    this.sprite = new PIXI.Container();
    this.sprite.interactive = true;
    cardCallbacks.forEach((callback) => {
      this.sprite.on(callback.action, callback.fn);
    });

    let buildingSprite: Sprite | SpineSprite;
    const spriteClass = SPRITES_SETTINGS[card.unitType]?.spriteClass;
    if (sprites[card.unitType]) {
      if (spriteClass) {
        buildingSprite = new spriteClass(sprites[card.unitType], {
          anchor: { x: 0.5, y: 0.5 },
        });
      } else {
        buildingSprite = new Sprite(sprites[card.unitType], {
          anchor: { x: 0.5, y: 0.5 },
        });
      }
    } else {
      if (spriteClass) {
        buildingSprite = new spriteClass(sprites[card.unitType + "Back"], {
          anchor: { x: 0.5, y: 0.5 },
        });
      } else {
        buildingSprite = new Sprite(sprites[card.unitType + "Back"], {
          anchor: { x: 0.5, y: 0.5 },
        });
      }
    }

    this.cardContainer = new PIXI.Container();

    this.cardContainer.addChild(cardSprite, CardFormMask, borderSprite);

    this.buildingContainer = new PIXI.Container();

    this.buildingContainer.addChild(buildingSprite);

    this.buildingContainer.filters = [
      new DropShadowFilter({
        distance: 5,
        blur: 2,
        resolution: window.devicePixelRatio,
        alpha: 0.2,
      }),
    ];

    if (spriteProps.x && spriteProps.y) {
      this.sprite.x = spriteProps.x;
      this.sprite.y = spriteProps.y;
    }

    this.addChild(this.sprite);
    this.container = container;
    this.container.interactive = true;

    this.container.on("pointermove", (event: PIXI.FederatedPointerEvent) => {
      this.onPointerMove(event);
    });

    this.sprite.addChild(this.buildingContainer, this.cardContainer);
    this.cardMode();

    this.zIndex = 1;
    this.myId = myId;
    this.engine = engine;

    this.sprites = sprites;
    this.spriteProps = spriteProps;
    this.unitClass = card.unitClass;
    this.unitType = card.unitType;
    this.socket = socket;
    this.gameObjectsContainer = gameObjectsContainer;

    this.unitProps = {
      width:
        BUILDINGS_CONFIG[this.unitType]?.width ||
        ENTITIES_CONFIG[this.unitType]?.width, // коэффициент скейла спрайта
      height:
        BUILDINGS_CONFIG[this.unitType]?.height ||
        ENTITIES_CONFIG[this.unitType]?.height,
    };
  }

  renderFunction() {
    if (this.isBuildMode) {
      // this.scale.set(1);
      // Проверяем находятся ли под карточкой unitы с unitClass building
      const spawnCoords = {
        x: this.gameObjectsContainer.rotation
          ? GAME_CANVAS_WIDTH * this.gameObjectsContainer.scale.x -
            this.sprite.x
          : this.sprite.x,
        y: this.gameObjectsContainer.rotation
          ? this.gameObjectsContainer.y - this.sprite.y
          : this.sprite.y - this.gameObjectsContainer.y,
      };

      const team = this.engine.users.find(
        (user) => user.id === this.myId
      )?.team;

      if (team === undefined) return;
      // Костыль из-за центрирования
      spawnCoords.x -= (this.cardContainer.width / 2) * (team === 1 ? 1 : -1);
      spawnCoords.y -= (this.cardContainer.height / 2) * (team === 1 ? 1 : -1);

      const units = this.engine.units.filter(
        (unit) => unit.unitClass === "building"
      );

      let isCollision = false;

      // Проверка коллизии с другими зданиями
      units.forEach((unit) => {
        if (
          checkCollision(
            {
              width: unit.width || 0,
              height: unit.height || 0,
              x: unit.x,
              y: unit.y,
            },
            {
              width: BUILDINGS_CONFIG[this.unitType].width - 1,
              height: BUILDINGS_CONFIG[this.unitType].height - 1,
              x: spawnCoords.x,
              y: spawnCoords.y,
            },
            {}
          )
        ) {
          isCollision = true;
        }
      });

      const line = this.engine.units.find((unit) => unit.unitType === "line");

      if (!line || !team || !line.height) return;

      // Проверка коллизии с линией (что мы на нужной стороне)
      if (
        checkCollision(
          {
            width: line.width || 0,
            height: this.gameObjectsContainer.rotation
              ? GAME_CANVAS_HEIGHT - line.y + line.height || 0
              : line.y + line.height || 0,
            x: line.x,
            y: this.gameObjectsContainer.rotation
              ? (GAME_CANVAS_HEIGHT - line.y) / 2 + line.y
              : line.y / 2,
          },
          {
            width: BUILDINGS_CONFIG[this.unitType].width,
            height: BUILDINGS_CONFIG[this.unitType].height,
            x: spawnCoords.x,
            y: spawnCoords.y,
          },
          {}
        )
      ) {
        isCollision = true;
      }

      if (isCollision) {
        this.canSpawn = false;
        this.alpha = 0.6;
        this.filters = [ErrorOutlineFilter];
      } else {
        this.canSpawn = true;
        this.spawnCoords = spawnCoords;
        this.filters = [];
        this.alpha = 1;
      }
    }
  }

  onPointerTap() {
    if (this.unitClass === "entity" && this.socket) {
      // Отправляем на сервер баланс пользователя
      if (import.meta.env.VITE_GAME_BALANCE_DEBUG_MODE) {
        this.socket.emit("spawnUnit", this.unitType, undefined, {
          tick: this.engine.lastRender,
          balance: this.engine.getUser(this.myId)?.balance,
        });
      } else {
        this.socket.emit("spawnUnit", this.unitType);
      }
    }
  }

  onPointerDown() {
    this.preventPosition = { x: 0, y: 0 };
    this.selected = true;
    this.zIndex = 2;
    if (this.unitClass === "building") {
      // this.sprite.scale.set(0.475);
    } else {
      this.sprite.y += 5;
    }
  }
  // // туториал (игра с ботом)
  onPointerUp() {
    if (this.unitClass === "building" && this.canSpawn && this.isBuildMode) {
      // Отправляем на сервер баланс пользователя
      if (import.meta.env.VITE_GAME_BALANCE_DEBUG_MODE) {
        this.socket.emit("spawnUnit", this.unitType, this.spawnCoords, {
          tick: this.engine.lastRender,
          balance: this.engine.getUser(this.myId)?.balance,
        });
      } else {
        this.socket.emit("spawnUnit", this.unitType, this.spawnCoords);
      }

      this.resetCard();
    } else {
      this.resetCard();
    }
  }

  resetCard() {
    this.cardMode();
    if (this.unitClass === "building") {
      this.bounce();
    }

    this.canSpawn = false;
    this.selected = false;
    this.zIndex = 1;
    this.sprite.x = this.spriteProps.x || 0;
    this.sprite.y = this.spriteProps.y || 0;
  }
  getCardPosition({ x, y }: { x: number; y: number }) {
    return {
      x: (x - this.container.getGlobalPosition().x) / this.container.scale.x,
      y: (y - this.container.getGlobalPosition().y) / this.container.scale.y,
    };
  }

  isOverGameContainer({ x, y }: { x: number; y: number }) {
    // Если карта находится над gameObjectsContainer, то она должна быть прозрачной

    if (this.gameObjectsContainer.rotation) {
      if (
        x > 0 &&
        x < GAME_CANVAS_WIDTH &&
        y > this.gameObjectsContainer.y - GAME_CANVAS_HEIGHT &&
        y < this.gameObjectsContainer.y
      ) {
        return true;
      }
    } else {
      if (
        x > 0 &&
        x < GAME_CANVAS_WIDTH &&
        y > this.gameObjectsContainer.y &&
        y < GAME_CANVAS_HEIGHT + this.gameObjectsContainer.y
      ) {
        return true;
      }
    }

    return false;
  }
  moveCard({ x, y }: { x: number; y: number }) {
    const newCoords = this.getCardPosition({ x, y });
    let newSpritePosition;
    if (this.isOverGameContainer({ x: newCoords.x, y: newCoords.y })) {
      this.buildMode();
      newSpritePosition = {
        x:
          Math.floor(
            (newCoords.x + (isEven(this.unitProps.width / 50) ? 25 : 0)) / 50
          ) *
            50 +
          (isEven(this.unitProps.width / 50) ? 0 : 25),
        y:
          Math.floor(
            (newCoords.y + (isEven(this.unitProps.height / 50) ? 25 : 0)) / 50
          ) *
            50 +
          (isEven(this.unitProps.height / 50) ? 0 : 25),
      };

      // Костыль для корректного отображения
      this.sprite.x = newSpritePosition.x + this.cardContainer.width / 2;
      this.sprite.y = newSpritePosition.y + this.cardContainer.height / 2;
    } else {
      this.cardMode();
      this.sprite.x = newCoords.x;
      this.sprite.y = newCoords.y;
    }
  }

  bounce() {
    // this.sprite.scale.set(0.475);
  }

  cardMode() {
    this.buildingContainer.alpha = 0;
    this.cardContainer.alpha = 1;
    this.isBuildMode = false;
    this.filters = [];
    this.alpha = 1;
    this.sprite.scale.set(1);
    this.sprite.pivot.set(
      this.cardContainer.width / 2,
      this.cardContainer.height / 2
    );
  }

  buildMode() {
    if (!this.isBuildMode) {
      this.buildingContainer.alpha = 1;
      this.cardContainer.alpha = 0;

      // this.sprite.pivot.set(0, 0);

      this.isBuildMode = true;
      this.selected = true;
    }
  }

  onPointerMove(event: PIXI.FederatedPointerEvent) {
    if (this.selected && this.unitClass === "building") {
      this.moveCard({ x: event.global.x, y: event.global.y });
    }
  }

  onPointerUpOutSide() {
    this.resetCard();
  }
}
