import { IPromocode } from "@/types/promocode";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type InterfaceState = {
  isPromotion: boolean;
  hasUnseenPromocode: boolean | null;
  rewardedGift: {
    maxAdWatchedTimes: number;
    updateTime: number;
    value: number;
  };
};

const initialState: InterfaceState = {
  isPromotion: false,
  hasUnseenPromocode: null,
  rewardedGift: {
    maxAdWatchedTimes: 0,
    updateTime: 0,
    value: 0,
  },
};

const InterfaceSlice = createSlice({
  name: "Interface",
  initialState,
  reducers: {
    setInterface(state: InterfaceState, action: PayloadAction<InterfaceState>) {
      Object.keys(action.payload).forEach((key) => {
        state[key as keyof InterfaceState] = action.payload[
          key as keyof InterfaceState
        ] as any;
      });
    },
  },
});

export default InterfaceSlice.reducer;
export const { setInterface } = InterfaceSlice.actions;
