import * as PIXI from "pixi.js";
import { ISpineResource } from "@pixi-spine/loader-base";
import { ISkeletonData } from "pixi-spine";

export type SpritesType = { [key: string]: PIXI.Texture };
export type SpineSpritesType = Record<string, ISpineResource<ISkeletonData>>;

const spineDataWaiter = (obj: { spineData: any }): Promise<void> => {
  return new Promise<void>((resolve) => {
    const checkData = () => {
      if (obj.spineData !== undefined) {
        resolve();
      } else {
        setTimeout(checkData, 100); // Повторная проверка через 100 миллисекунд
      }
    };

    checkData();
  });
};

export default class PixiLoader {
  sprites: SpritesType;
  tasks = 0;
  completedTasks = 0;
  onLoad = (sprites: { [key: string]: PIXI.Texture }) => undefined;
  onProgress = (progress: number) => undefined;
  onComplete = (sprites: { [key: string]: PIXI.Texture }) => undefined;

  constructor() {
    this.sprites = {};
  }

  async add(name: string, path: string) {
    this.tasks += 1;
    const data = await PIXI.Assets.load(path);
    if (data.texture) {
      this.sprites[name] = data.texture;
    } else {
      this.sprites[name] = data;

      // Ждем догрузку спайн даты
      if (path.includes(".json")) await spineDataWaiter(data);
    }
    this.completedTasks += 1;
    if (this.tasks === this.completedTasks) {
      this.onLoad(this.sprites);
      this.onComplete(this.sprites);
    }
    this.onProgress((this.completedTasks / this.tasks) * 100);
  }

  async loadJson({
    json,
    github_url,
    callback,
  }: {
    json: object;
    github_url?: string;
    callback?: (sprites: SpritesType) => void;
  }) {
    const text = JSON.stringify(json);
    const images_list = JSON.parse(text);

    images_list["files"].forEach((i: any) => {
      if (github_url) {
        this.add(i.name, github_url + i.file);
      } else {
        this.add(i.name, "." + i.file);
      }
    });

    this.onLoad = () => {
      if (callback) {
        callback(this.sprites);
      }
    };
  }
}
