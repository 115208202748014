import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postRequest, request } from "@/utils/request";
import { CampaignType } from "./campaign.panel.reducer";
import { Prizes } from "./campaign.panel.reducer";

type CompletedLevel = {
  status: "win" | "draw" | "lose";
  levelId: number;
  amountStars: number;
};

type CampaignListType = {
  levels: CampaignType[];
  level: CampaignType;
  currentLevel: {
    startTime: number;
    endTime: number;
    cards: string[];
  };
  allAmountStars: number;
  amountStarsCurrentLevel: 0 | 1 | 2 | 3;
  completedLevels: CompletedLevel[];
  flag: boolean;
  testLevelId: number;
};

const initialState: CampaignListType = {
  levels: [],
  level: {
    _id: 0,
    image: "",
    name: {
      en: {
        text: "",
      },
      ru: {
        text: "",
      },
    },
    prizes: [],
    stars: [],
  },
  currentLevel: {
    cards: [],
    startTime: 0,
    endTime: 0,
  },
  allAmountStars: 0,
  amountStarsCurrentLevel: 0,
  completedLevels: [],
  flag: false,
  testLevelId: 0,
};

export const getLevels = createAsyncThunk<{ levels: CampaignType[] }>(
  "CampaignList/getLevels",
  async function () {
    const getLevelsResponse = await request<{ levels: CampaignType[] }>({
      method: "campaign/getLevels",
    });
    return getLevelsResponse;
  }
);

export const setPassedLevel = createAsyncThunk<
  { levels: { id: number; amountStars: number }[] },
  { id: number; amountStars: number; userId: number }
>("Campaign/setPassedLevel", async function ({ id, amountStars, userId }) {
  const setsetPassedLevelResponse = await postRequest<{
    levels: { id: number; amountStars: number }[];
  }>({
    method: "campaign/setPassedLevel",
    params: {
      id: id,
      amountStars: amountStars,
      userId: userId,
    },
  });
  return setsetPassedLevelResponse;
});

export const getPassedLevels = createAsyncThunk<
  { levels: { id: number; amountStars: number }[] },
  { userId: number }
>("Campaign/getPassedLevels", async function ({ userId }) {
  const getPassedLevelsResponse = await request<{
    levels: { id: number; amountStars: number }[];
  }>({
    method: "campaign/getPassedLevels",
    params: {
      userId: userId,
    },
  });

  return getPassedLevelsResponse;
});

export const setLevelBot = createAsyncThunk<
  { levelId: number },
  { userId: number; levelId: number | null }
>("Campaign/setLevelBot", async function ({ userId, levelId }) {
  const setLevelBotResponse = await postRequest<{ levelId: number }>({
    method: "campaign/setLevelBot",
    params: {
      userId: userId,
      levelId: levelId,
    },
  });
  return setLevelBotResponse;
});

function haveSameElements(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

function areElementsCompletelyDistinct(arr1: string[], arr2: string[]) {
  const set2 = new Set(arr2);

  for (const element of arr1) {
    if (set2.has(element)) {
      return false;
    }
  }

  return true;
}

const CampaignListSlice = createSlice({
  name: "CampaignList",
  initialState,
  reducers: {
    getLevel(state, action: PayloadAction<{ id: number }>) {
      // const completedPrize: Prizes = {
      //   typePrize: "coins",
      //   count: 80,
      // };
      const tempLevel = state.levels.find(
        (item) => item._id === action.payload.id
      );
      if (tempLevel) {
        state.level = tempLevel;
      }
      
      // const completeLevel = state.completedLevels.find((item) => {
      //   return item.levelId === state.level._id;
      // });
      // if (completeLevel) {
      //   state.level.prizes = [completedPrize];
      // }
    },
    setStartTime(state, action: PayloadAction<{ time: number }>) {
      state.currentLevel.startTime = action.payload.time;
    },
    setFlag(state, action: PayloadAction<{ flag: boolean }>) {
      state.flag = action.payload.flag;
    },
    setEndTime(
      state,
      action: PayloadAction<{
        time: number;
        statusLevel: "win" | "draw" | "lose";
      }>
    ) {
      state.currentLevel.endTime = action.payload.time;
      const timeCompleteLevel =
        state.currentLevel.endTime - state.currentLevel.startTime;
      const threeStarsRestriction = state.level.stars[0];
      const twoStarsRestriction = state.level.stars[1];
      const oneStarRestriction = state.level.stars[2];
      let flagOneStar = 0;
      let flagTwoStars = 0;
      let flagThreeStars = 0;
      let flag = false;

      if (!flag) {
        threeStarsRestriction.forEach((item) => {
          if (item.typeRestriction === "time" && item.count) {
            if (action.payload.statusLevel === "win") {
              if (timeCompleteLevel <= item.count) {
                flagThreeStars += 1;
              }
            }
          }
          if (item.typeRestriction === "onlyCards" && item.cards) {
            if (action.payload.statusLevel === "win") {
              if (haveSameElements(state.currentLevel.cards, item.cards)) {
                flagThreeStars += 1;
              }
            }
          }
          if (item.typeRestriction === "exceptCards" && item.cards) {
            if (action.payload.statusLevel === "win") {
              if (
                areElementsCompletelyDistinct(
                  state.currentLevel.cards,
                  item.cards
                )
              ) {
                flagThreeStars += 1;
              }
            }
          }
        });
        if (flagThreeStars === threeStarsRestriction.length) {
          state.amountStarsCurrentLevel += 3;
          flag = true;
        }
      }
      if (!flag) {
        twoStarsRestriction.forEach((item) => {
          if (item.typeRestriction === "time" && item.count) {
            if (action.payload.statusLevel === "win") {
              if (timeCompleteLevel <= item.count) {
                flagTwoStars += 1;
              }
            }
          }
          if (item.typeRestriction === "onlyCards" && item.cards) {
            if (action.payload.statusLevel === "win") {
              if (haveSameElements(state.currentLevel.cards, item.cards)) {
                flagTwoStars += 1;
              }
            }
          }
          if (item.typeRestriction === "exceptCards" && item.cards) {
            if (action.payload.statusLevel === "win") {
              if (
                areElementsCompletelyDistinct(
                  state.currentLevel.cards,
                  item.cards
                )
              ) {
                flagTwoStars += 1;
              }
            }
          }
        });
        if (flagTwoStars === twoStarsRestriction.length) {
          state.amountStarsCurrentLevel += 2;
          flag = true;
        }
      }
      if (!flag) {
        oneStarRestriction.forEach((item) => {
          if (item.typeRestriction === "time" && item.count) {
            if (action.payload.statusLevel === "win") {
              if (timeCompleteLevel <= item.count) {
                flagOneStar += 1;
              }
            }
          }
          if (item.typeRestriction === "onlyCards" && item.cards) {
            if (action.payload.statusLevel === "win") {
              if (haveSameElements(state.currentLevel.cards, item.cards)) {
                flagOneStar += 1;
              }
            }
          }
          if (item.typeRestriction === "exceptCards" && item.cards) {
            if (action.payload.statusLevel === "win") {
              if (
                areElementsCompletelyDistinct(
                  state.currentLevel.cards,
                  item.cards
                )
              ) {
                flagOneStar += 1;
              }
            }
          }
        });
        if (flagOneStar === oneStarRestriction.length) {
          state.amountStarsCurrentLevel += 1;
          flag = true;
        }
      }
    },
    setCurrentLevelStars(
      state,
      action: PayloadAction<{ amountStars: 0 | 1 | 2 | 3 }>
    ) {
      state.amountStarsCurrentLevel = action.payload.amountStars;
    },
    setAllAmountStars(state, action: PayloadAction<{ amountStars: number }>) {
      state.allAmountStars = action.payload.amountStars;
    },
    setDeckLevel(state, action: PayloadAction<{ card: string }>) {
      state.currentLevel.cards.push(action.payload.card);
      const tempArr = new Set(state.currentLevel.cards);
      state.currentLevel.cards = [...tempArr];
      console.log(state.currentLevel.cards);
    },
    setCompletedLevels(
      state,
      action: PayloadAction<{
        status: "win" | "draw" | "lose";
      }>
    ) {
      state.completedLevels.push({
        levelId: state.level._id,
        status: action.payload.status,
        amountStars: state.amountStarsCurrentLevel,
      });

      //   const completedLevel = state.completedLevels.find((item) => {
      //     item.levelId === state.level._id;
      //   });
      //   if (completedLevel) {
      //     completedLevel.amountStars =
      //       state.level.amountStars < state.amountStarsCurrentLevel
      //         ? state.amountStarsCurrentLevel
      //         : state.level.amountStars;

      //     state.level.amountStars = completedLevel.amountStars;
      //     state.completedLevels.forEach((item) => {
      //       if (item.levelId === state.level._id) {
      //         item.amountStars = state.level.amountStars;
      //       }
      //     });
      //     state.allAmountStars = 0;
      //     state.completedLevels.forEach((item) => {
      //       state.allAmountStars += item.amountStars;
      //     });
      //   }

      // console.log(state.allAmountStars);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLevels.fulfilled, (state, action) => {
        state.levels = action.payload.levels;
      })
      .addCase(setPassedLevel.fulfilled, (state, action) => {
        state.completedLevels = [];

        action.payload.levels.forEach((item) => {
          state.completedLevels.push({
            amountStars: item.amountStars,
            levelId: item.id,
            status: "win",
          });
        });
        state.allAmountStars = 0;
        state.completedLevels.forEach((item) => {
          state.allAmountStars += item.amountStars;
        });
      })
      .addCase(getPassedLevels.fulfilled, (state, action) => {
        console.log(action.payload.levels);
        action.payload.levels.forEach((item) => {
          state.completedLevels.push({
            amountStars: item.amountStars,
            levelId: item.id,
            status: "win",
          });
        });
        state.allAmountStars = 0;
        state.completedLevels.forEach((item) => {
          state.allAmountStars += item.amountStars;
        });
      })
      .addCase(setLevelBot.fulfilled, (state, action) => {
        state.testLevelId = action.payload.levelId;
      });
  },
});

export default CampaignListSlice.reducer;
export const {
  getLevel,
  setDeckLevel,
  setAllAmountStars,
  setCurrentLevelStars,
  setStartTime,
  setEndTime,
  setCompletedLevels,
  setFlag,
} = CampaignListSlice.actions;
