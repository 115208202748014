import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { images } from "@/data/url";
import { AppColorScheme } from "./AppSuspense";
import { Background } from "./Background";

const blink = keyframes`
  from {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
`;

const Text = styled.span<{ colorScheme: AppColorScheme }>`
  font-size: 18px;
  animation: ${blink} 2.5s infinite linear;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: ${({ colorScheme }) => (colorScheme === "dark" ? "white" : "black")};
`;

const Img = styled.img`
  padding: 40px;
`;

type ClickPanelProps = {
  redirectFunction: () => void;
  colorScheme: AppColorScheme;
};

const ClickPanel: React.FC<ClickPanelProps> = ({
  redirectFunction,
  colorScheme,
}) => {
  return (
    <Background onClick={redirectFunction} colorScheme={colorScheme}>
      <Img src={images.sheepRoyaleLogo} />
      <Text colorScheme={colorScheme}>Нажмите, чтобы продолжить...</Text>
    </Background>
  );
};
export default React.memo(ClickPanel);
