import { IS_OK, IS_TG, IS_VK } from "@/config/constants";
// import { retrieveLaunchParams } from "@tma.js/sdk";

export default function getId() {
  if (IS_VK) {
    return (
      window.location.search.split("vk_user_id=")[1]?.split("&")[0] ||
      window.location.search.split("viewer_id=")[1]?.split("&")[0]
    );
  }
  if (IS_OK)
    return window.location.search.split("logged_user_id=")[1]?.split("&")[0];

  if (IS_TG) {
    // const { initData } = retrieveLaunchParams();
    return window.tma?.initData?.user?.id;
  }
}
