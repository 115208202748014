import Container from "../utils/Containers";
// import { AdjustmentFilter } from "@pixi/filter-adjustment";
import SpineSprite from "../utils/SpineSprite";
import Sprite from "../utils/Sprite";
import { DropShadowFilter, AdjustmentFilter } from "pixi-filters";

// По умолчанию все классы спрайтов обычные, но если спрайт наследует другой клас, например SpineSprite для анимации, прописываем его сюда
export const SPRITES_SETTINGS: {
  [key: string]: {
    spriteClass?: typeof SpineSprite | typeof Sprite;
    zIndex?: number;
  };
} = {
  mortar: {
    spriteClass: SpineSprite,
  },
  crashedPlane: {
    spriteClass: SpineSprite,
  },
  deadFarm: {
    spriteClass: SpineSprite,
  },
  invisibleSheep: {
    spriteClass: SpineSprite,
  },
  mole: {
    spriteClass: SpineSprite,
  },
  moleHeapTrace: {
    spriteClass: SpineSprite,
  },
  moleDugCell: {
    spriteClass: SpineSprite,
  },
  cycloneEffect: {
    spriteClass: SpineSprite,
  },
  balloonExplosionEffect: {
    spriteClass: SpineSprite,
  },
  teslaTower: {
    zIndex: 3,
  },
};
export default class ContainersConfig {
  containters = {
    entityWithoutDropdown: new Container({ zIndex: 10 }),

    entities: new Container({
      zIndex: 10,
      filters: [
        new DropShadowFilter({
          color: 0x000020,
          alpha: 0.2,
          distance: 25,
          rotation: -30,
          blur: 2,
          resolution: window.devicePixelRatio,
        }),
      ],
    }),

    entitiesPotioned: new Container({
      zIndex: 10,
      filters: [new AdjustmentFilter({ gamma: 2, red: 3, blue: 2 })],
    }),

    overBuildingsParticlies: new Container({
      zIndex: 7,
    }),

    overEntitiesParticles: new Container({
      zIndex: 11,
    }),

    buildings: new Container({ zIndex: 5 }),

    line: new Container({
      zIndex: 6,
      filters: [
        new DropShadowFilter({
          color: 0x000020,
          alpha: 0.7,
          distance: 3,
          rotation: 90,
          blur: 1,
          resolution: window.devicePixelRatio,
        }),
      ],
    }),

    trail: new Container({ zIndex: 1 }),
  } as {
    [key: string]: Container;
  };

  units = {
    bigSheep: {
      parent: this.containters.entities,
    },
    sheep: {
      parent: this.containters.entities,
    },
    bee: {
      parent: this.containters.entities,
    },
    royalSheep: {
      parent: this.containters.entities,
    },
    royalSheepServant: {
      parent: this.containters.entities,
    },
    necromancerSheep: {
      parent: this.containters.entities,
    },
    necromancerSheepSoul: {
      parent: this.containters.entities,
    },
    line: {
      parent: this.containters.line,
    },
    fastSheep: {
      parent: this.containters.entities,
    },
    farm: {
      parent: this.containters.buildings,
    },
    deadFarm: {
      parent: this.containters.buildings,
    },
    doubleFarm: {
      parent: this.containters.buildings,
    },
    pasture: {
      parent: this.containters.buildings,
    },
    crashedPlane: {
      parent: this.containters.buildings,
    },
    beehiveFarm: {
      parent: this.containters.buildings,
    },
    teslaTower: {
      parent: this.containters.buildings,
    },
    mortar: {
      parent: this.containters.buildings,
    },
    core: {
      parent: this.containters.overBuildingsParticlies,
    },
    coreExplosionEffect: {
      parent: this.containters.overBuildingsParticlies,
    },
    icePeak: {
      parent: this.containters.buildings,
    },
    potion: {
      parent: this.containters.buildings,
    },

    balloonBase: {
      parent: this.containters.buildings,
    },
    balloon: {
      parent: this.containters.entities,
    },
    balloonExplosionEffect: {
      parent: this.containters.overEntitiesParticles,
    },
    cycloneSheep: {
      parent: this.containters.entities,
    },
    cycloneEffect: {
      parent: this.containters.overBuildingsParticlies,
    },
    invisibleSheep: {
      parent: this.containters.entities,
    },
    mole: {
      parent: this.containters.entityWithoutDropdown,
    },
    moleUnderGround: {
      parent: this.containters.entityWithoutDropdown,
    },
    moleDugCell: {
      parent: this.containters.buildings,
    },
    moleHeapTrace: {
      parent: this.containters.trail,
    },
  } as {
    [key: string]: {
      parent: Container;
    };
  };
}
