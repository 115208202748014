import { Howl } from "howler";
import { sounds } from "../url";
import { RandomHowl } from "@/utils/randomHowl";

export const GAME_SOUND_VOLUME = 0.3;
export const DESTROY_SOUND_VOLUME = 0.12;

// export const beeHitSound = new RandomHowl([
//   {
//     src: [sounds.beeHits[0]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.beeHits[1]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.beeHits[2]],
//     volume: GAME_SOUND_VOLUME,
//   },
// ]);

export const beeHitSound = new Howl({
  src: sounds.beeHits[0],
  volume: GAME_SOUND_VOLUME - 0.1,
});

export const electroTowerSound = new Howl({
  src: [sounds.electroTower[0]],
  volume: GAME_SOUND_VOLUME,
});
// export const electroTowerSound = new RandomHowl([
//   {
//     src: [sounds.electroTower[0]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.electroTower[1]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.electroTower[2]],
//     volume: GAME_SOUND_VOLUME,
//   },
// ]);

export const ghostSheepSound = new Howl({
  src: sounds.ghostSheep,
  volume: GAME_SOUND_VOLUME,
});

export const sheepHitSound = new Howl({
  src: [sounds.sheepHits[0]],
  volume: GAME_SOUND_VOLUME,
});
// new RandomHowl([
//   {
//     src: [sounds.sheepHits[0]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.sheepHits[1]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.sheepHits[2]],
//     volume: GAME_SOUND_VOLUME,
//   },
// ]);

export const pastureBuildSound = new Howl({
  src: [sounds.pastureBuilds[0]],
  volume: GAME_SOUND_VOLUME,
});
// new RandomHowl([
//   {
//     src: [sounds.pastureBuilds[0]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.pastureBuilds[1]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.pastureBuilds[2]],
//     volume: GAME_SOUND_VOLUME,
//   },
// ]);

export const farmBuildSound = new Howl({
  src: [sounds.farmBuilds[0]],
  volume: GAME_SOUND_VOLUME,
});
// new RandomHowl([
//   {
//     src: [sounds.farmBuilds[0]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.farmBuilds[1]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.farmBuilds[2]],
//     volume: GAME_SOUND_VOLUME,
//   },
// ]);

export const doubleFarmBuildSound = new Howl({
  src: [sounds.farmBuilds[0]],
  volume: GAME_SOUND_VOLUME,
});
// new RandomHowl([
//   {
//     src: [sounds.doubleFarmBuilds[0]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.doubleFarmBuilds[1]],
//     volume: GAME_SOUND_VOLUME,
//   },
// ]);

export const crashedPlaneBuildSound = new Howl({
  src: [sounds.crashedPlaneBuild],
  volume: GAME_SOUND_VOLUME,
});

export const icePeakBuildSound = new Howl({
  src: [sounds.icePeakBuild],
  volume: GAME_SOUND_VOLUME,
});

export const icePeakDestroySound = new Howl({
  src: [sounds.icePeakDestroy],
  volume: GAME_SOUND_VOLUME - 0.1,
});

export const potionBuildSound = new Howl({
  src: [sounds.potionBuild],
  volume: GAME_SOUND_VOLUME,
});

export const potionEffectSound = new Howl({
  src: [sounds.potionEffect],
  volume: GAME_SOUND_VOLUME - 0.15,
});

export const mortarBuildSound = new Howl({
  src: [sounds.mortarBuild],
  volume: GAME_SOUND_VOLUME,
});

export const mortarDestroySound = new Howl({
  src: [sounds.mortarDestroy],
  volume: DESTROY_SOUND_VOLUME,
});

export const mortarShootSound = new Howl({
  src: [sounds.mortarShoot],
  volume: GAME_SOUND_VOLUME,
});

export const coreHitGroundSound = new Howl({
  src: [sounds.coreHitGround],
  volume: GAME_SOUND_VOLUME,
});

export const deadFarmBuildSound = new Howl({
  src: [sounds.deadFarmBuilds[0]],
  volume: GAME_SOUND_VOLUME,
});
//  new RandomHowl([
//   {
//     src: [sounds.deadFarmBuilds[0]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.deadFarmBuilds[1]],
//     volume: GAME_SOUND_VOLUME,
//   },
// ]);

export const buildingsDestroySound = new Howl({
  src: [sounds.buildingsDestroy],
  volume: DESTROY_SOUND_VOLUME,
});

export const gameStartSound = new Howl({
  src: [sounds.gameStart],
  volume: GAME_SOUND_VOLUME,
});

export const gameEndSound = new Howl({
  src: [sounds.gameEnd],
  volume: GAME_SOUND_VOLUME,
});

export const invisibleSheepSpawnSound = new Howl({
  src: [sounds.invisibleSheepSounds.spawn],
  volume: GAME_SOUND_VOLUME,
});

export const invisibleSheepDieSound = new Howl({
  src: [sounds.invisibleSheepSounds.die],
  volume: GAME_SOUND_VOLUME,
});

export const moleDigSound = new Howl({
  src: [sounds.moleDig[0]],
  volume: GAME_SOUND_VOLUME - 0.1,
});

// export const moleDigSound = new RandomHowl(
//   sounds.moleDig.map((item) => {
//     return {
//       src: [item],
//       volume: GAME_SOUND_VOLUME,
//     };
//   })
// );

export const moleSpawnSound = new Howl({
  src: [sounds.moleSpawn[0]],
  volume: GAME_SOUND_VOLUME - 0.1,
});

// export const moleSpawnSound = new RandomHowl(
//   sounds.moleSpawn.map((item) => {
//     return {
//       src: [item],
//       volume: GAME_SOUND_VOLUME,
//     };
//   })
// );

// export const balloonSpawnSound = new Howl({
//   src: [sounds.balloonBase[0]],
//   volume: GAME_SOUND_VOLUME - 0.2,
// });

export const balloonExplosionSound = new Howl({
  src: [sounds.balloonBase[1]],
  volume: GAME_SOUND_VOLUME,
});

// new RandomHowl([
//   {
//     src: [sounds.balloonBase[1]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.balloonBase[2]],
//     volume: GAME_SOUND_VOLUME,
//   },
//   {
//     src: [sounds.balloonBase[3]],
//     volume: GAME_SOUND_VOLUME,
//   },
// ]);

export const cycloneFadeIn = new Howl({
  src: [sounds.cycloneSheep[0]],
  volume: GAME_SOUND_VOLUME,
});

export const cycloneFadeOut = new Howl({
  src: [sounds.cycloneSheep[1]],
  volume: GAME_SOUND_VOLUME,
});

export const cycloneLoop = new Howl({
  src: [sounds.cycloneSheep[2]],
  volume: GAME_SOUND_VOLUME,
  loop: true,
});
