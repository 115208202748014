import { Howl, HowlOptions, SpatialPosition } from "howler";

export class RandomHowl {
  howls: Howl[] = [];
  loop?: boolean;
  current?: Howl;
  isPlaying?: boolean = false;
  // перемотка в миллисекундах
  seekTime?: number;
  constructor(sounds: HowlOptions[], { loop }: { loop?: boolean } = {}) {
    sounds.forEach((soundProps) => {
      this.howls.push(
        new Howl({
          ...soundProps,

          onend: () => {
            if (this.loop) {
              this.isPlaying = false;
              this.play();
            }
          },
        })
      );
    });

    this.loop = loop;
  }

  play() {
    if (this.loop) {
      if (this.isPlaying) return;
      this.isPlaying = true;
    }

    const randomIndex = Math.floor(Math.random() * this.howls.length);
    if (this.seekTime) {
      this.howls[randomIndex].seek(this.seekTime);
      this.seekTime = 0;
    }
    this.howls[randomIndex].play();
    this.current = this.howls[randomIndex];
  }

  stop() {
    this.isPlaying = false;
    this.current?.stop();
  }
  seek(time: number) {
    this.seekTime = time;
  }
  pos(x: number, y: number, z: number, id?: number) {
    this.current?.pos(x, y, z, id);
  }
}
