import * as PIXI from "pixi.js";
import parameterInstaller from "./parameterInstaller";
import { Spine } from "pixi-spine";

export type SpriteProps = {
  x?: number;
  y?: number;
  scale?: number;
  width?: number;
  height?: number;
  anchor?: { x?: number; y?: number };
  rotation?: number;
  alpha?: number;
  isButton?: boolean;
  interactive?: boolean;
  zIndex?: number;
  callbacks?: Array<{
    action: string;
    fn: (event: PIXI.InteractionEvent & { [key: string]: any }) => void;
  }>;
};

// Добавляем  dragonBones.PixiArmatureDisplay в типы класса SpineSprite
class SpineSprite extends PIXI.Sprite {
  selected = false;
  // sprite: dragonBones.PixiArmatureDisplay;
  spine: Spine;
  parameterInstaller = parameterInstaller;
  constructor(texture: any, spriteProps: SpriteProps = {}) {
    super();
    try {
      this.spine = new Spine(
        Object.setPrototypeOf(
          { ...texture.spineData },
          Object.getPrototypeOf(texture.spineData)
        )
      );
    } catch (err) {
      console.info(texture, spriteProps);
      return;
    }

    this.parameterInstaller(spriteProps);

    this.addChild(this.spine);
  }

  destroy() {
    super.destroy();
  }
}

export default SpineSprite;
