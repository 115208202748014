// import { retrieveLaunchParams } from "@tma.js/sdk";

export default function getAuthParams() {
  return window.location.search.split("?")[1];
}

export function getAuthTgParams() {
  // const { initDataRaw } = retrieveLaunchParams();
  // Первое - при входе из бота, Второе - при входе через direct link
  return window.tma?.initDataRaw || window.location.hash.split("#")[1];
}
