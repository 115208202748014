import { HOMEPAGE } from "@/config/constants";
import { PixiLoader, ImagesJSON, LazyImagesJSON } from "@/pixi";
import { SpritesType } from "@/pixi/loader/PixiLoader";
import * as PIXI from "pixi.js";

export const loader = new PixiLoader();
const isLoadStarted = {
  default: false,
  lazy: false,
};
export let progress = 0;
let sprites = {};

export default (
  onLoad?: (sprites: { [key: string]: PIXI.Texture }) => unknown,
  onProgress?: (progress: number) => unknown
) => {
  if (onProgress) {
    loader.onProgress = (progress) => {
      progress = Math.floor(progress);
      onProgress(progress);
    };
  }

  if (onProgress && onLoad) {
    loader.onComplete = (data) => {
      progress = 100;
      onProgress(progress);
      onLoad(data);
    };
  }

  return { progress, sprites };
};

export const pixiImagesLoaderStart = (json: "default" | "lazy" = "default") => {
  if (!isLoadStarted[json]) {
    isLoadStarted[json] = true;

    loader.loadJson({
      json: json === "lazy" ? LazyImagesJSON : ImagesJSON,
      github_url: HOMEPAGE,
      callback: (loadedSprites: SpritesType) => {
        sprites = loadedSprites;
        document.fonts.load("1px 'Russo One'");
      },
    });
  }
};
