import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import {
  APP_LANG,
  HOMEPAGE,
  IS_OK,
  IS_VK,
  LOCATION_HASH,
} from "@/config/constants";

console.info("USE LANG", APP_LANG, LOCATION_HASH);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,
    defaultNS: "translation",
    fallbackLng: IS_VK || IS_OK ? "ru" : "en",
    lng: APP_LANG,
    backend: {
      loadPath: `${HOMEPAGE}/locales/{{lng}}.json`,
    },
  });

export default i18n;
