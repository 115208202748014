import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import { IChestTypes } from "@/panels/ChestOpening/prize";

/**
 * Доступные типы заданий
 * При добавлении нового обязательно обновить handler в статусах
 */
export enum QuestType {
  INVITE_FRIENDS = "inviteFriend",
  GAMES_COUNT = "GAMES_COUNT",
  GAMES_WINS = "GAMES_WINS",
}

export interface Requirement {
  type: QuestType;
  value: number;
}

export interface IQuest {
  // разные языки
  texts: { [key: string]: { description: string } };
  requirements: Requirement[];
}

export interface IStatus {
  _id: string;
  title: string;
  requiredQuest: IQuest;
  giftChestType: IChestTypes;
  subTitle: string;
  icon: string;
  vkStatusID: number;
}

const initialState: IStatus[] = [
  {
    _id: "",
    title: "",
    subTitle: "",
    icon: "",
    giftChestType: "common",
    requiredQuest: "",
    vkStatusID: 0,
  },
];

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setStatuses: (_state, action: PayloadAction<IStatus[]>) => {
      return action.payload;
    },
  },
});

export const getStatuses = (state: RootState) => state.Status;

export const { setStatuses } = statusSlice.actions;
export default statusSlice.reducer;
