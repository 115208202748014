import * as PIXI from "pixi.js";
import Sprite, { SpriteProps } from "./Sprite";

/** Функция для удобного прокидывания параметров в pixi объекты */
export default function (
  this: Sprite,
  {
    x = 0,
    y = 0,
    scale = 0.5,
    width,
    height,
    anchor,
    rotation = 0,
    alpha,
    isButton,
    interactive,
    zIndex,
    callbacks = [],
  }: SpriteProps = {}
) {
  this.x = x;
  this.y = y;

  if (rotation !== undefined) {
    this.rotation = rotation;
  }

  if (zIndex !== undefined) {
    this.zIndex = zIndex;
  }

  if (scale && this.scale?.set) {
    this.scale.set(scale);
  }

  if (width) {
    this.width = width;
  }

  if (height) {
    this.height = height;
  }
  if (this.anchor) {
    if (anchor?.x !== undefined) {
      this.anchor.x = anchor.x;
    }
    if (anchor?.y !== undefined) {
      this.anchor.y = anchor.y;
    }
  }

  if (alpha !== undefined) {
    this.alpha = alpha;
  }

  if (interactive !== undefined) {
    this.interactive = true;
  }

  if (isButton) {
    this.interactive = true;
    this.cursor = "pointer";

    if (callbacks) {
      callbacks.forEach(({ action, fn }) => {
        this.on(action, fn);
      });
    }
  }
}
