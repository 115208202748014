export enum OK_Methods {
  showAd = "showAd",
  requestBannerAds = "requestBannerAds",
  showBannerAds = "showBannerAds",
  hideBannerAds = "hideBannerAds",
  getFriends = "friends.get",
  showPayment = "showPayment",
  joinGroup = "joinGroup",
  storageSet = "storage.set",
  storageGet = "storage.get",
}

export enum OK_Statuses {
  OK = "ok",
  ERROR = "error",
}

type Data = { method: OK_Methods; status: OK_Statuses; data: any };
type Callback = { (data: Data, method?: OK_Methods): void };
interface Callbacks {
  [key: string]: Callback[];
}

export const OK = {
  callbacks: {} as Callbacks,
  subscribers: [] as Callback[],

  on: function (event: OK_Methods, callback?: Callback): Promise<Data> {
    if (this.callbacks[event] === undefined) {
      this.callbacks[event] = [];
    }
    if (callback) this.callbacks[event].push(callback);
    return new Promise((resolve) => {
      this.callbacks[event].push(resolve);
    });
  },
  emit: function (data: Data) {
    const callbacks = this.callbacks[data.method];
    this.subscribers && this.subscribers.forEach((callback) => callback(data));
    callbacks && callbacks.forEach((callback) => callback(data));
  },
  subscribe: function (callback: Callback) {
    this.subscribers.push(callback);
  },
};

global.API_callback = function (
  method: OK_Methods,
  status: OK_Statuses,
  data: any
): void {
  console.log(method);
  console.log(status);
  console.log(data);
  OK.emit({ method, status, data });
};
