import axios from "axios";
import axiosRetry from "axios-retry";
import { SERVER_URL, USER_PLATFORM } from "@/config/constants";
import getAuthParams, { getAuthTgParams } from "./getAuthParams";

axiosRetry(axios, {
  retries: 180,
  retryDelay: (retryCount) => {
    let retryTimeout = retryCount * 500;
    if (retryTimeout > 1500) {
      retryTimeout = 1500;
    }
    return (retryTimeout = 1500); // time interval between retries
  },
});

export async function request<T>({
  auth = true,
  method,
  params,
  controller,
  ignoreErrors,
}: {
  method: string;
  auth?: boolean;
  params?: { [key: string]: any };
  controller?: AbortController;
  ignoreErrors?: boolean;
}) {
  const headers: { Authorization?: string } = {};
  if (auth) {
    if (USER_PLATFORM === "tg") {
      headers.Authorization = `${getAuthTgParams()}`;
    } else {
      headers.Authorization = `${getAuthParams()}`;
    }
  }
  const response = await axios.get<T>(`${SERVER_URL}/${method}`, {
    headers,
    params,
    signal: controller?.signal,
    validateStatus: ignoreErrors ? () => true : undefined,
  });

  return response.data;
}

export async function postRequest<T>({
  auth = true,
  method,
  params,
}: {
  method: string;
  auth?: boolean;
  params?: { [key: string]: any };
}) {
  const headers: { Authorization?: string } = {};

  if (auth) {
    if (USER_PLATFORM === "tg") {
      headers.Authorization = `${getAuthTgParams()}`;
    } else {
      headers.Authorization = `${getAuthParams()}`;
    }
  }

  const response = await axios.post<T>(`${SERVER_URL}/${method}`, params, {
    headers,
  });
  return response.data;
}
