import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CardDataType } from "../../data/cards";

type ProfileState = {
  changeMode: boolean;
  selected: (CardDataType & { entity: string }) | null;
};

const initialState: ProfileState = {
  changeMode: false,
  selected: null,
};

const ProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    setChangeMode(state: ProfileState, action: PayloadAction<boolean>) {
      state.changeMode = action.payload;
    },
    setSelected(
      state: ProfileState,
      action: PayloadAction<(CardDataType & { entity: string }) | null>
    ) {
      state.selected = action.payload;
    },
  },
});

export default ProfileSlice.reducer;
export const { setChangeMode, setSelected } = ProfileSlice.actions;
