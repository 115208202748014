import { GAME_HEIGHT, GAME_WIDTH } from "@/../../engine/config";
import * as PIXI from "pixi.js";

export const CANVAS_HEIGHT = 900; // Размер игрового поля в высоту
export const CANVAS_WIDTH = 900;

export const GAME_CANVAS_WIDTH = GAME_WIDTH;
export const GAME_CANVAS_HEIGHT = GAME_HEIGHT;

let CANVAS_OFFSET = 60 * (900 / window.innerHeight); // Сдвиг неиспользуемой зоны сверху экрана

if (window.innerHeight * window.innerWidth > 450 * 900) {
  CANVAS_OFFSET = 0;
}

if (CANVAS_OFFSET > 125) {
  CANVAS_OFFSET = 125;
}

export const DECK_CARDS_OFFSET_X = 14; // сдвиг между карточками
export const DECK_CARDS_OFFSET_Y = 70; // сдвиг от самой высокой точки деки
export const DECK_CARDS_OFFSET_LEFT = 15; // сдвиг от левого края деки
export const CARD_SIZE = { width: 76, height: 104 };
export { CANVAS_OFFSET };

export const MAIN_TEXT_STYLE = (
  fontSize: number,
  { wordWrapWidth }: { wordWrapWidth?: number } = {}
) => {
  return new PIXI.TextStyle({
    dropShadow: true,
    dropShadowAlpha: 0.5,
    dropShadowAngle: 90,
    dropShadowDistance: 2,
    fill: "white",
    fontFamily: "Russo One",
    strokeThickness: 6,
    wordWrap: true,
    wordWrapWidth: wordWrapWidth || 200,
    fontSize: fontSize * 2,
  });
};
