import { IS_DEV, IS_OK, IS_TG, IS_VK } from "@/config/constants";
import { OK, OK_Methods, OK_Statuses } from "@/libs/ok/api_callback";
import bridge, {
  BannerAdLayoutType,
  BannerAdLocation,
} from "@vkontakte/vk-bridge";

import { Ads, AdsOriginal } from "@tg-ads-mediation/ads";

class TGAds {
  _ads?: Ads;

  async showBottomBanner() {
    return (await this.getAds()).showBottomBanner();
  }

  async showRewardedVideo() {
    return (await this.getAds()).showRewardedVideo();
  }

  async getAds() {
    if (!this._ads) {
      this._ads = await Ads.create({
        key: "riQJgd@Tr7e#W%BdgcFGs8iV",
        // for dev mode or testing
        test: IS_DEV,
      });
    }

    return this._ads;
  }
}

export const TG_ADS = new TGAds();

const BANNER_SHOW_TIME = 3100;

class AdsControllerClass {
  lastShowedBanner = 0;
  bannedLocations = [
    "#/game",
    "#/main",
    "#/game?blackScreenPopout=startGameTimer",
  ];
  hardBannedLocations = ["#/game"];
  showBannerTimeout?: NodeJS.Timeout;

  constructor() {
    if (IS_VK && !IS_DEV) {
      bridge.subscribe(({ detail }) => {
        console.info(detail);
        if (
          (detail.type === "VKWebAppShowBannerAdResult" ||
            detail.type === "VKWebAppBannerAdUpdated") &&
          detail.data.result === true
        ) {
          if (!this.lastShowedBanner) this.lastShowedBanner = Date.now();
        }
        if (
          detail.type === "VKWebAppHideBannerAdResult" &&
          detail.data.result === true
        ) {
          // console.info(
          //   "BANNER SHOWED:",
          //   (Date.now() - this.lastShowedBanner) / 1000,
          //   "с."
          // );
          this.lastShowedBanner = 0;
        }

        if (
          detail.type === "VKWebAppShowBannerAdResult" ||
          detail.type === "VKWebAppBannerAdUpdated"
        ) {
          // Скрываем баннер если он в игре вылез
          if (this.hardBannedLocations.includes(window.location.hash)) {
            this.hideBanner(true);
            return;
          }
          if (
            this.bannedLocations.includes(window.location.hash) &&
            detail.data.result === true
          ) {
            this.hideBanner();
          } else {
            console.info("ads show in: " + window.location.hash);
          }
        }
      });
    }

    if (IS_OK) {
      OK.subscribe(({ method, status, data }) => {
        if (method === OK_Methods.showBannerAds && data === "true") {
          if (!this.lastShowedBanner) this.lastShowedBanner = Date.now();
        }

        if (method === OK_Methods.hideBannerAds && data === true) {
          // console.info(
          //   "BANNER SHOWED:",
          //   (Date.now() - this.lastShowedBanner) / 1000,
          //   "с."
          // );
          this.lastShowedBanner = 0;
        }

        if (method === OK_Methods.showBannerAds && data === "true") {
          // Скрываем баннер если он в игре вылез
          if (this.hardBannedLocations.includes(window.location.hash)) {
            this.hideBanner(true);
            return;
          }
          if (
            this.bannedLocations.includes(window.location.hash) &&
            data === "true"
          ) {
            this.hideBanner();
          } else {
            console.info("ads show in: " + window.location.hash);
          }
        }
      });
    }
  }

  hideBanner(hardHide?: boolean) {
    const FAKE_ADS = document.getElementById("fakeAds");
    // Прячем баннер только спустя 3 секунды или если он ещё даже 1,5 секунды не показался
    if (
      (Date.now() - this.lastShowedBanner >= BANNER_SHOW_TIME &&
        this.lastShowedBanner) ||
      hardHide
    ) {
      if (IS_VK) {
        bridge.send("VKWebAppHideBannerAd");
        console.info(
          "BANNER SHOWED:",
          (Date.now() - this.lastShowedBanner) / 1000,
          "с.",
          "HH:",
          hardHide
        );
      }
      if (FAKE_ADS) {
        FAKE_ADS.style.opacity = "0";
      }
      if (IS_OK) {
        FAPI.invokeUIMethod(OK_Methods.hideBannerAds);
        console.info(
          "BANNER SHOWED:",
          (Date.now() - this.lastShowedBanner) / 1000,
          "с."
        );
      }

      if (this.showBannerTimeout) {
        clearTimeout(this.showBannerTimeout);
        this.showBannerTimeout = undefined;
      }
      this.lastShowedBanner = 0;
    } else {
      if (this.lastShowedBanner)
        setTimeout(() => {
          if (IS_VK) {
            bridge.send("VKWebAppHideBannerAd");
            console.info(
              "BANNER SHOWED:",
              (Date.now() - this.lastShowedBanner) / 1000,
              "с."
            );
          }
          if (FAKE_ADS) {
            FAKE_ADS.style.opacity = "0";
          }
          if (IS_OK) {
            FAPI.invokeUIMethod(OK_Methods.hideBannerAds);
            console.info(
              "BANNER SHOWED:",
              (Date.now() - this.lastShowedBanner) / 1000,
              "с."
            );
          }
          if (this.showBannerTimeout) {
            clearTimeout(this.showBannerTimeout);
            this.showBannerTimeout = undefined;
          }
          this.lastShowedBanner = 0;
        }, BANNER_SHOW_TIME - (Date.now() - this.lastShowedBanner));
    }
  }

  setBannerShowed() {
    if (!this.lastShowedBanner) this.lastShowedBanner = Date.now();
  }

  showBanner({
    layout_type = BannerAdLayoutType.OVERLAY,
  }: {
    layout_type?: BannerAdLayoutType;
  }) {
    if (IS_VK) {
      bridge.send("VKWebAppShowBannerAd", {
        banner_location: BannerAdLocation.BOTTOM,
        layout_type,
      });
    }
    if (IS_OK) {
      FAPI.invokeUIMethod(OK_Methods.requestBannerAds);
      OK.on(OK_Methods.requestBannerAds).then((data) => {
        if (data.status === OK_Statuses.OK) {
          FAPI.invokeUIMethod(OK_Methods.showBannerAds, "top");
        }
      });
    }

    if (IS_TG) {
      //TG_ADS.showBottomBanner();
    }
    // const FAKE_ADS = document.getElementById("fakeAds");
    // if (!this.lastShowedBanner && !this.showBannerTimeout) {
    //   this.showBannerTimeout = setTimeout(() => {
    //     // if (FAKE_ADS) {
    //     //   FAKE_ADS.style.opacity = "1";
    //  //   this.lastShowedBanner = Date.now();
    //     clearTimeout(this.showBannerTimeout);
    //     console.info("StartShowed");
    //     // }
    //   }, Math.random() * 10000);
    // }
  }
}

const AdsContoller = new AdsControllerClass();
export default AdsContoller;
