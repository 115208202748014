import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import ClickPanel from "./ClickPanel";
import KotbreadPanel from "./KotbreadPanel";
import bridge from "@vkontakte/vk-bridge";
import { IS_OK, IS_VK } from "@/config/constants";
import pixiImagesLoader, {
  pixiImagesLoaderStart,
} from "@/hooks/pixiImagesLoader";

const App = lazy(() => import("../../App"));

const LoadIndicatorStyled = styled.div`
  display: none;
`;

type LoadIndicatorProps = {
  onLoad: () => void;
};

const LoadIndicator: React.FC<LoadIndicatorProps> = ({ onLoad }) => {
  useEffect(() => {
    return () => onLoad();
  }, []);
  return <LoadIndicatorStyled />;
};

export type AppColorScheme = "light" | "dark";

const AppSuspense = () => {
  const [activePanel, setActivePanel] = useState("kotbread");
  const [isLoaded, setIsLoaded] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(true);
  const [lazySpritesLoaded, setLazySpritesLoaded] = useState(false);
  const [lazySpritesLoadingProgress, setLazySpritesLoadingProgress] =
    useState(0);
  const [showApp, setShowApp] = useState(false);
  const [colorScheme, setColorScheme] = useState<AppColorScheme>("dark");

  const redirectFunction = useCallback(() => {
    setActivePanel("kotbread");
  }, []);
  // Загружаем спрайты использующиеся в меню, эмоции и фон
  useEffect(() => {
    pixiImagesLoaderStart("lazy");
    pixiImagesLoader(
      () => {
        setLazySpritesLoaded(true);
      },
      (progress) => {
        setLazySpritesLoadingProgress(progress);
      }
    );
  }, []);

  useEffect(() => {
    if (IS_VK) {
      bridge.subscribe(({ detail }) => {
        if (detail.type === "VKWebAppUpdateConfig") {
          setColorScheme(
            detail.data.scheme.includes("light") ? "light" : "dark"
          );
        }
      });
    }
    if (IS_OK) {
      setColorScheme("light");
    }
    bridge.subscribe(({ detail }) => {
      if (detail.type === "VKWebAppUpdateConfig") {
        setColorScheme(
          detail?.data?.scheme?.includes("light") ? "light" : "dark"
        );
      }
    });
  }, []);

  useEffect(() => {
    if (
      isLoaded &&
      animationFinished &&
      activePanel === "kotbread" &&
      lazySpritesLoaded
    ) {
      setShowApp(true);
      setActivePanel("");
      // Прячем баннер строго при загрузке аппа и попадании на главную
      //if (timerId) clearInterval(timerId);
    }
  }, [isLoaded, animationFinished, activePanel, lazySpritesLoaded]);

  return (
    <>
      {/* <div
        style={{ position: "absolute", zIndex: "100", opacity: 0 }}
        id="fakeAds"
      >
        Фейковая реклама
      </div> */}
      {activePanel === "click" ? (
        <ClickPanel
          redirectFunction={redirectFunction}
          colorScheme={colorScheme}
        />
      ) : (
        activePanel === "kotbread" && (
          <KotbreadPanel
            onEnd={() => setAnimationFinished(true)}
            colorScheme={colorScheme}
            progress={lazySpritesLoadingProgress}
          />
        )
      )}
      <Suspense fallback={<LoadIndicator onLoad={() => setIsLoaded(true)} />}>
        <App isShow={showApp} />
      </Suspense>
    </>
  );
};

export default React.memo(AppSuspense);
