import { UnitInitialType } from "../engine/methods/units/addUnit";

export interface Entity {
  type: "Entity";
  speedY?: number; // Скорость указывается в пикселях в секунду
  width: number;
  height: number;
  y?: number;
  x?: number;
  lineMove?: number; // Сдвиг линии при столкновении
  slowingDown?: number; // Замедление
  speed?: number;

  initialAlpha?: number; // Начальное значение прозрачности
  distanceToLine?: number; // Смена спрайта на расстоянии от бревна
  cellsCount?: number; // Количество клеток разрыть
  visibleTraceTime?: number; // Сколько виден след в секундах
  ignoreUnits?: UnitInitialType["unitType"][];
  targetUnits?: UnitInitialType["unitType"][];
  eventDelay?: number; // Задержка события
  eventRange?: number; // Радиус события
  eventDistanceToLine?: number; // Событие, происходящее на расстоянии от линии
  animationDuration?: number; // Длительность анимации

  windSpeedY?: number; // Скорость ветра
  rangeYTop?: number;
  weight?: number; // Вес предмета, чем больше вес - тем сложнее сдуть
}

export interface Building {
  type: "Building";
  width: number;
  height: number;

  /*addMoneyEarn*/
  speedEarn?: number;
  earnDelay?: number;

  /*addUnitSpawner*/
  spawnUnits?: UnitInitialType[];
  unitSpawnerDelay?: number;

  /*Ветер чтобы ускорять юнитов*/
  windSpeedY?: number;

  /*Задержка между атаками*/
  attackDelay?: number;

  ignoreUnits?: UnitInitialType["unitType"][];
  rangeYTop?: number;
  rangeX?: number;
  freezeTime?: number;
  boostAttack?: number;

  lifeTime?: number; // Время жизни постройки в мс
  animationDuration?: number; // Длительность анимации
}

export const isEntity = (unit: Entity | Building): unit is Entity =>
  unit.type === "Entity";
export const isBuilding = (unit: Entity | Building): unit is Building =>
  unit.type === "Building";

export const ENTITIES_CONFIG: Record<string, Entity> = {
  sheep: {
    type: "Entity",
    speedY: 100,
    height: 28.44,
    width: 17,
    lineMove: 1.1,
    weight: 1,
  },
  core: {
    type: "Entity",
    speed: 100,
    height: 12,
    width: 12,
  },
  coreExplosionEffect: {
    type: "Entity",
    height: 100,
    width: 100,
  },
  necromancerSheepSoul: {
    type: "Entity",
    speedY: 130,
    height: 28.44,
    width: 17,
    lineMove: 0.4,
    weight: 0.5,
  },
  necromancerSheep: {
    type: "Entity",
    speedY: 90,
    height: 28.44,
    width: 17,
    lineMove: 0.75,
    weight: 1,
  },
  bigSheep: {
    type: "Entity",
    speedY: 45,
    height: 38.1,
    width: 26,
    lineMove: 1.5,
    weight: 1.8,
  },
  line: {
    type: "Entity",
    y: 550 / 2,
    x: 300 / 2,
    width: 300,
    height: 26,
    slowingDown: 0.05,
    weight: 8,
  },
  fastSheep: {
    type: "Entity",
    speedY: 180,
    height: 25.33,
    width: 15,
    lineMove: 0.29, // атака бревна
    weight: 0.5,
  },
  royalSheep: {
    type: "Entity",
    speedY: 70,
    height: 25.33,
    width: 15,
    lineMove: 0.68,
    weight: 4,
  },

  royalSheepServant: {
    type: "Entity",
    speedY: 70,
    height: 28.44,
    width: 17,
    lineMove: 0.32,
    weight: 1,
  },

  bee: {
    type: "Entity",
    speedY: 135,
    height: 20,
    width: 20,
    lineMove: 0.173,
    weight: 0.5,
  },

  balloon: {
    type: "Entity",
    speedY: 50,
    height: 28.44,
    width: 17,
    eventDelay: 400, // Задержка взрыва бочки (мс)
    eventRange: 75, // Радиус поиска врагов
    ignoreUnits: [
      "line",
      "core",
      "coreExplosionEffect",
      "balloonExplosionEffect",
      "mole",
      "moleUnderGround",
      "moleHeapTrace",
      "invisibleSheep",
      "cycloneEffect",
    ],
    weight: 4,
  },

  balloonExplosionEffect: {
    type: "Entity", // Эффект взрыва шарика
    height: 20,
    width: 20,
  },

  cycloneSheep: {
    type: "Entity",
    speedY: 20,
    height: 28.44,
    width: 17,
    lineMove: 0.5,
    eventRange: 50, // Радиус циклона
    targetUnits: ["line"],
    windSpeedY: 150, // скорость отталкивания
    rangeYTop: 100,
    weight: 8, // вес
    // ignoreUnits: [
    //   "line",
    //   "core",
    //   "coreExplosionEffect",
    //   "balloonExplosionEffect",
    //   "cycloneSheep",
    //   "cycloneEffect",
    //   "mole",
    //   "moleHeapTrace",
    //   "moleUnderGround",
    // ],
  },

  cycloneEffect: {
    type: "Entity",
    height: 28.44,
    width: 17,
  },

  invisibleSheep: {
    type: "Entity",
    speedY: 60,
    height: 28.44,
    width: 17,
    lineMove: 1.1,
    eventDistanceToLine: 100, //Становится видимой на расстоянии от линии
    initialAlpha: 0.3, // Стартовая непрозрачность (от 0 до 1)
  },

  mole: {
    type: "Entity", // Крот
    height: 28.44,
    width: 17,
    animationDuration: 1300, //длительность анимации 1х = 2750ms (учитывается только анимация вперед, анимация назад длится столько же)
  },

  moleUnderGround: {
    type: "Entity", // Крот под землей
    speed: 200,
    height: 28.44,
    width: 17,
    cellsCount: 5, // Сколько клеток разрывает
  },

  moleHeapTrace: {
    type: "Entity", // След остающийся за кротом
    height: 50,
    width: 50,
    visibleTraceTime: 1500, // Сколько виден след в миллисекундах
  },
};

export const BUILDINGS_CONFIG: Record<string, Building> = {
  farm: {
    type: "Building",
    width: 50,
    height: 50,
    speedEarn: 2, // сколько приносит монет
    earnDelay: 2880, // как часто
  },
  doubleFarm: {
    type: "Building",
    width: 100,
    height: 50,
    speedEarn: 3,
    earnDelay: 2736,
  },
  deadFarm: {
    type: "Building",
    width: 100,
    height: 100,
    speedEarn: 3,
    earnDelay: 3100,
  },
  pasture: {
    type: "Building",
    width: 50,
    height: 50,
    spawnUnits: [{ unitType: "sheep" }],
    unitSpawnerDelay: 8000,
  },
  crashedPlane: {
    type: "Building",
    width: 50,
    height: 50,
    windSpeedY: 20, // скорость отталкивания
    rangeYTop: 425, // дальность поражения
    ignoreUnits: [
      "core",
      "coreExplosionEffect",
      "balloonExplosionEffect",
      "mole",
      "moleUnderGround",
      "moleHeapTrace",
      "cycloneEffect",
    ], // кого игнорирует при взаимодействии
  },
  beehiveFarm: {
    type: "Building",
    width: 50,
    height: 50,
    spawnUnits: [
      { unitType: "bee", x: -15 },
      { unitType: "bee", x: 15 },
    ], // Кого создает
    unitSpawnerDelay: 6000,
    speedEarn: 1, // сколько приносить монет
    earnDelay: 2500, // как часто
  },
  teslaTower: {
    type: "Building",
    width: 50,
    height: 50,
    attackDelay: 3000, // как часто атакует (мс)
    ignoreUnits: [
      "line",
      "coreExplosionEffect",
      "balloonExplosionEffect",
      "invisibleSheep",
      "mole",
      "moleUnderGround",
      "moleHeapTrace",
      "moleDugCell",
      "cycloneEffect",
    ], // кого игнорирует при атаке
    rangeYTop: 120, // дистанция атаки по Y вверх от объекта
    rangeX: 140, // дистанция атаки по X
  },
  mortar: {
    type: "Building",
    width: 50,
    height: 50,
    attackDelay: 9000, // как часто атакует (мс)
    rangeYTop: 200, // дистанция атаки по Y вверх от объекта
    rangeX: 200, // дистанция атаки по X
    ignoreUnits: ["moleDugCell"],
  },
  icePeak: {
    type: "Building",
    width: 50,
    height: 50,
    freezeTime: 2000, // Время на которое iceBlock замораживает линию
  },
  potion: {
    type: "Building",
    width: 50,
    height: 50,
    boostAttack: 0.16, // (1 + boostAttack)*lineMove = сила удара персонажа
    ignoreUnits: [
      "core",
      "line",
      "coreExplosionEffect",
      "mole",
      "invisibleSheep",
      "moleUnderGround",
      "moleHeapTrace",
      "balloon",
      "balloonExplosionEffect",
      "cycloneEffect",
    ], // кого игнорирует при взаимодействии
  },

  moleDugCell: {
    type: "Building", // Разрытая кротом клетка
    width: 50,
    height: 50,
    lifeTime: 11000, // Нельзя строить здания в миллисекундах
    animationDuration: 900, // 1x = 1625ms
  },

  balloonBase: {
    type: "Building", // База вылета шариков
    width: 50,
    height: 50,
    spawnUnits: [{ unitType: "balloon" }],
    unitSpawnerDelay: 5000,
  },
};
