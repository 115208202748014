import * as PIXI from "pixi.js";
import parameterInstaller from "./parameterInstaller";

export type SpriteProps = {
  x?: number;
  y?: number;
  scale?: number;
  width?: number;
  height?: number;
  anchor?: { x?: number; y?: number };
  rotation?: number;
  alpha?: number;
  isButton?: boolean;
  interactive?: boolean;
  zIndex?: number;
  callbacks?: Array<{
    action: string;
    fn: (event: PIXI.InteractionEvent & { [key: string]: any }) => void;
  }>;
};

class Sprite extends PIXI.Sprite {
  selected = false;

  parameterInstaller = parameterInstaller;
  constructor(sprite: PIXI.Texture, spriteProps: SpriteProps = {}) {
    super(sprite);
    this.parameterInstaller(spriteProps);
  }
}

export default Sprite;
