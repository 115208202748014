import styled from "@emotion/styled";
import { AppColorScheme } from "./AppSuspense";
import "../../styles/index.css";
export const Background = styled.div<{ colorScheme: AppColorScheme }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ colorScheme }) =>
    colorScheme === "light"
      ? "var(--main-bg-color-light)"
      : "var(--main-bg-color-dark)"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
