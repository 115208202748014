import { init } from "@tma.js/sdk";

try {
  // Пытаемся инициализировать tma, т.к. это старший по импорту файл
  window.tma = init();
} catch (err) {
  console.error(err);
}

const SECOND = 1000;

export const IS_DEV = import.meta.env.VITE_APP_MODE === "development";
export const IS_PROD = import.meta.env.VITE_APP_MODE === "production";

export const LOCATION_SEARCH = window.location.search.split("?")[1];
export const LOCATION_HASH =
  window.location.hash.replace("#", "") !== ""
    ? window.location.hash.replace("#", "")
    : decodeURIComponent(
        LOCATION_SEARCH?.match(/[&?]hash=.+?($|&)/)?.[0]
          ?.slice(6)
          .replace("&", "") || ""
      );

export const IS_VK = window.location.search.includes(
  "?vk_access_token_settings"
);
export const IS_OK = window.location.search.includes("api.ok.ru");
export const IS_TG = window.tma !== undefined;

export const APP_LANG: "ru" | "en" =
  IS_VK || IS_OK
    ? "ru"
    : window.tma?.initDataRaw
    ? window.tma.initDataRaw
        .match(/language_code.+?[a-z]{2}/)?.[0]
        ?.slice(-2) || "en"
    : "en";

export const SERVER_URL = IS_PROD
  ? "https://sheeproyale.site"
  : "http://localhost:30000";

export const VK_CHAT_JOIN_LINK =
  "https://vk.me/join/dGuFH6p4iZXrENLrPFgSByoXpzr4iQZNHZg=";

export const PLAYDECK_LINK = "https://t.me/playdeckbot?start=lZI2n";

export const VK_GROUP_LINK = "https://vk.com/sheep_royale";

export const STORY_IMAGE = "https://i.ibb.co/4N4rZF2/Frame-1321313561-1.png";

export const ONLINE_DATA_GET_INTERVAL = 10 * SECOND;

export const DEV_SERVER_DOMAIN = "http://127.0.0.1:5000";

export const ONBOARDING_GAME_CREATION_SHOWN_KEY =
  "sr_ONBOARDING_GAME_CREATION_SHOWN";

export const GROUP_ID = 209019197;
export const OK_GROUP_ID = 70000005735606;
export const OK_CHAT_JOIN_LINK =
  "https://ok.ru/messages/join/ZLqbUHdEhf0jIp0buJ8YCr0EV06ChqAxMVX8XvMTXfg";

export const PLATFORM = {
  OK: "ok",
  VK: "vk",
  TG: "tg",
} as const;

export const TON_WALLET = "0QAAufpX8PLcuMqSU6OZN5T98xPB5Rn0R4HGAg_WQ1BvGWMT";

export const HOMEPAGE = IS_PROD
  ? IS_VK
    ? "https://mitursky.github.io/SheepRoyale2Build/assets"
    : "https://sheeproyale.ru/uploads"
  : "/src/assets";

export const VK_APP_URL = "https://vk.com/sheeproyale";
export const OK_APP_URL = "https://ok.ru/game/512002100876";
export const OK_APP_ID = 512002100876;
export const VK_APP_ID = 51491054;
export const TG_APP_URL = "t.me/sheeproyale2_game_bot/game";

export const USER_PLATFORM = (() => {
  if (IS_VK) {
    return PLATFORM.VK;
  }
  if (IS_TG) {
    return PLATFORM.TG;
  }
  if (IS_OK) {
    return PLATFORM.OK;
  }

  return "undefined";
})();

// TODO: Добавить определение платформы
const VK_PLATFORM = IS_VK
  ? window.location.search?.split("vk_platform=")[1]?.split("&")[0]
  : undefined;

console.log(USER_PLATFORM);

export const IS_VK_IOS: boolean = VK_PLATFORM
  ? VK_PLATFORM.includes("mobile_ipad") ||
    VK_PLATFORM.includes("mobile_iphone") ||
    VK_PLATFORM.includes("mobile_iphone_messenger")
  : false;

export const SENTRY_DSN =
  "https://be57a9d35cae6693deaa4d74b583a895@sentry.kotbread.ru/2";

export const VK_CHECKBACK_URL =
  "https://vk.com/checkback#coupons/my?utm_source=sheeproyal2";
