import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SnackbarType = {
  type: "error" | "url";
  text: string;
};

type AppState = {
  loaderProgress: number;
  snackbar: null | SnackbarType;
};

const initialState: AppState = {
  loaderProgress: 0,
  snackbar: null,
};

const AppSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    setProgress(state, action: PayloadAction<number>) {
      state.loaderProgress = action.payload;
    },
    closeSnackbar(state) {
      state.snackbar = null;
    },
    setSnackbar(state, action: PayloadAction<SnackbarType>) {
      state.snackbar = action.payload;
    },
  },
});

export default AppSlice.reducer;
export const { setProgress, closeSnackbar, setSnackbar } = AppSlice.actions;
