import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postRequest, request } from "@/utils/request";

export type Prizes = {
  typePrize: string;
  count?: number;
  rarity?: string;
};

type Star = {
  typeRestriction: string;
  count?: number;
  cards?: string[];
};

type TupleStars = [Star, Star, Star];

export type CampaignType = {
  _id: number;
  name: {
    en: {
      text: string;
    };
    ru: {
      text: string;
    };
  };
  image: string;
  prizes: Prizes[];
  stars: TupleStars[];
};

const initialState: CampaignType = {
  _id: 0,
  name: {
    en: {
      text: "",
    },
    ru: {
      text: "",
    },
  },
  image: "",
  prizes: [],
  stars: [],
};

export const getLevel = createAsyncThunk<
  { level: CampaignType },
  { id: number }
>("Campaign/getLevel", async function ({ id }) {
  const getLevelResponse = await request<{ level: CampaignType }>({
    method: "campaign/getLevel",
    params: {
      id: id,
    },
  });
  return getLevelResponse;
});

const CampaignSlice = createSlice({
  name: "Campaign",
  initialState,
  reducers: {
    getCurrentLevel(
      state: CampaignType,
      action: PayloadAction<{ levels: CampaignType[]; id: number }>
    ) {
      console.log(action.payload);
      // const tempState = action.payload.levels.find(
      //   (item) => item._id === action.payload.id
      // );
      // console.log(tempState)
      // if (tempState) {
      //   state._id = tempState._id;
      //   state.image = tempState.image;
      //   state.name = tempState.name;
      //   state.prizes = tempState.prizes;
      //   state.stars = tempState.stars;
      // }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLevel.fulfilled, (state, action) => {
      state = action.payload.level;
    });
  },
});

export default CampaignSlice.reducer;

export const { getCurrentLevel } = CampaignSlice.actions;
