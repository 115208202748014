import * as PIXI from "pixi.js";

export default class Container extends PIXI.Container {
  constructor({
    zIndex = 0,
    filters,
    sortableChildren = false,
  }: {
    zIndex?: number;
    filters?: PIXI.Filter[];
    sortableChildren?: boolean;
  }) {
    super();
    this.zIndex = zIndex;
    this.filters = filters || [];
    this.sortableChildren = sortableChildren;
  }
}
