/**  Количество кадров в секунду (цикл рендеринга движка) */
export const TICK = 1000 / 60;

/** Количество сохраняемых последних состояний игры (необходимо для отображения верной картины в условиях сильного пинга)*/
export const MAX_LAST_STATES = 150;

export const GAME_WIDTH = 300;
export const GAME_HEIGHT = 550;

export const CELL_SIZE = 50;
export const CELL_WIDTH = GAME_WIDTH / CELL_SIZE;
export const CELL_HEIGHT = GAME_HEIGHT / CELL_SIZE;

export const START_GAME_BALANCE = 10;
export const DURATION_OF_GAME = 60 * 3 * 1000;
export const AUTO_ADD_BALANCE = 1.8;
export const BEFORE_GAME_DURATION = 5 * 1000;
export const EMOTION_COOL_DOWN = 2000;
